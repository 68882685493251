<template>
	<div class="dialogMode">
		<el-button type="primary" @click="modeSelection">控制模式选择</el-button>
		<el-button type="primary" @click="pattern({
          'id': '7',
          'title': '回水温度控制(补偿回温)',
          'bindingDio': 'dialogBc',
          'show': false
        }, 6)">补偿回水温度设置</el-button>
		<el-dialog title="控制模式选择" :visible.sync="dialogModeSelection" width="30%">
			<div class="project">
				<div class="m-t-20" v-for="(item, key) in control.filter((item)=> item.show)" :key="key">
					<el-radio v-model="radioSelect" :label="item['id']" @change="pattern(item, key)" border>
						{{item['title']}}</el-radio>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogModeSelection = false">关 闭</el-button>
			</span>
		</el-dialog>

		<el-dialog title="手动开度控制" :visible.sync="dialogHm" width="30%">
			<el-form ref="dialogHmForm" :model="dialogHmForm" :rules="Hmrules" label-width="100px">
				<el-form-item class="m-t-10" label="阀门开度" prop="fmkd">
					<el-input v-model="dialogHmForm.fmkd" placeholder="确认阀门开度调整至(%)"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="提示" v-show="dialogHmForm.fmkd">
					<div style="color:#fc2424;font-weight: bold;font-size: 15px;;">
						请确定将阀门开度调至{{dialogHmForm.fmkd}}%
					</div>
				</el-form-item>
				<el-form-item class="m-t-10">
					<el-button type="primary" @click="onSubmit('dialogHmForm')">确定</el-button>
					<el-button @click="cancelDio('dialogHmForm', 'dialogHm')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		<el-dialog title="智能控制" :visible.sync="dialogHs" width="30%">
			<el-form ref="dialogHsForm" :model="dialogHsForm" :rules="Hsrules">
				<el-form-item class="m-t-10" label="请选择换热站" prop="heatExchangeValue">
					<div class="parameter">
						<!-- <el-select v-model="dialogHsForm.heatExchangeValue" placeholder="请选择换热站">
							<el-option v-for="(item, key) in heatExchangeStationArr" :label="item" :value="item"
								:key="key"></el-option>
						</el-select>
						<div class="settingcs">
							<el-button type="primary" plain @click="pattern({
								'id': '6',
								'title': '参数设置',
								'bindingDio': 'dialogCs',
								'show': false
								}, 5)">参数设置</el-button>
						</div> -->
					</div>
				</el-form-item>
				<el-form-item class="m-t-10" label="提示:">
					<div class="d-flex" style="color:red">请确认设置补偿回水温度（默认为0℃）</div>
				</el-form-item>
				<el-form-item class="m-t-10">
					<el-button type="primary" @click="onSubmit('dialogHsForm')">确定</el-button>
					<el-button @click="cancelDio('dialogHsForm', 'dialogHs')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- <el-dialog title="回水温度控制" :visible.sync="dialogZn" width="30%">
			<el-form ref="dialogLlForm" :model="dialogLlForm" :rules="Llrules" label-width="100px">
				<el-form-item class="m-t-10" label="目标值" prop="mbz">
					<el-input v-model="dialogLlForm.mbz"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="其他参数" prop="qtcs">
					<el-input v-model="dialogLlForm.qtcs"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="调控周期" prop="tkzq">
					<el-input v-model="dialogLlForm.tkzq" placeholder="h"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="调控精度" prop="tkjd">
					<el-input v-model="dialogLlForm.tkjd" placeholder="℃"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="最小开度" prop="zxkd">
					<el-input v-model="dialogLlForm.zxkd" placeholder=%></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="最大开度" prop="zdkd">
					<el-input v-model="dialogLlForm.zdkd" placeholder=%></el-input>
				</el-form-item>
				<el-form-item class="m-t-10">
					<el-button type="primary">确定</el-button>
					<el-button @click="cancelDio('dialogLlForm', 'dialogZn')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog> -->
		
		
		
		<el-dialog title="流量控制" :visible.sync="dialogLl" width="30%">
			<el-form ref="dialogLlForm" :model="dialogLlForm" :rules="Llrules" label-width="100px">
				<el-form-item class="m-t-10" label="目标值" prop="mbz">
					<el-input v-model="dialogLlForm.mbz"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="其他参数" prop="qtcs">
					<el-input v-model="dialogLlForm.qtcs"></el-input>
				</el-form-item>

				<el-form-item class="m-t-10" label="调控周期" prop="tkzq">
					<el-input v-model="dialogLlForm.tkzq" placeholder="h"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="调控精度" prop="tkjd">
					<el-input v-model="dialogLlForm.tkjd" placeholder="℃"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="最小开度" prop="zxkd">
					<el-input v-model="dialogLlForm.zxkd" placeholder=%></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="最大开度" prop="zdkd">
					<el-input v-model="dialogLlForm.zdkd" placeholder=%></el-input>
				</el-form-item>

				<el-form-item class="m-t-10">
					<el-button type="primary" @click="onSubmit('dialogLlForm')">确定</el-button>
					<el-button @click="cancelDio('dialogLlForm', 'dialogLl')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog title="室温控制" :visible.sync="dialogSt" width="30%">
			<el-form ref="dialogLlForm" :model="dialogLlForm" :rules="Llrules" label-width="100px">
				<el-form-item class="m-t-10" label="目标值" prop="mbz">
					<el-input v-model="dialogLlForm.mbz"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="其他参数" prop="qtcs">
					<el-input v-model="dialogLlForm.qtcs"></el-input>
				</el-form-item>
			
				<el-form-item class="m-t-10" label="调控周期" prop="tkzq">
					<el-input v-model="dialogLlForm.tkzq" placeholder="h"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="调控精度" prop="tkjd">
					<el-input v-model="dialogLlForm.tkjd" placeholder="℃"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="最小开度" prop="zxkd">
					<el-input v-model="dialogLlForm.zxkd" placeholder=%></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="最大开度" prop="zdkd">
					<el-input v-model="dialogLlForm.zdkd" placeholder=%></el-input>
				</el-form-item>
				<el-form-item class="m-t-10">
					<el-button type="primary">确定</el-button>
					<el-button @click="cancelDio('dialogLlForm', 'dialogZn')">取消</el-button>
				</el-form-item>
			</el-form>
			<!-- <span slot="footer" class="dialog-footer">
				<el-button @click="dialogSt = false">关 闭</el-button>
			</span> -->
		</el-dialog>

		<el-dialog title="回水温度控制" :visible.sync="dialogZn" width="30%">
			<el-form ref="dialogLlForm" :model="dialogLlForm" :rules="Llrules" label-width="100px">
				<el-form-item class="m-t-10" label="目标值" prop="mbz">
					<el-input v-model="dialogLlForm.mbz"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="其他参数" prop="qtcs">
					<el-input v-model="dialogLlForm.qtcs"></el-input>
				</el-form-item>

				<el-form-item class="m-t-10" label="调控周期" prop="tkzq">
					<el-input v-model="dialogLlForm.tkzq" placeholder="h"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="调控精度" prop="tkjd">
					<el-input v-model="dialogLlForm.tkjd" placeholder="℃"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="最小开度" prop="zxkd">
					<el-input v-model="dialogLlForm.zxkd" placeholder=%></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="最大开度" prop="zdkd">
					<el-input v-model="dialogLlForm.zdkd" placeholder=%></el-input>
				</el-form-item>

				<el-form-item class="m-t-10">
					<el-button type="primary">确定</el-button>
					<el-button @click="cancelDio('dialogLlForm', 'dialogZn')">取消</el-button>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogZn = false">关 闭</el-button>
			</span>
		</el-dialog>


		<el-dialog title="参数设置" :visible.sync="dialogCs" width="30%">
			<el-form ref="dialogCsForm" :model="dialogCsForm" :rules="Csrules" label-width="100px">
				<el-form-item class="m-t-10" label="Kp" prop="kp">
					<el-input v-model="dialogCsForm.kp"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="Ki" prop="ki">
					<el-input v-model="dialogCsForm.ki"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="kd" prop="kd">
					<el-input v-model="dialogCsForm.kd"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="阀开度下限Umin" prop="uMin">
					<el-input v-model="dialogCsForm.uMin"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10" label="阀开度上限Umax" prop="uMax">
					<el-input v-model="dialogCsForm.uMax"></el-input>
				</el-form-item>
				<el-form-item class="m-t-10">
					<el-button type="primary" @click="onSubmit('dialogCsForm')">确定</el-button>
					<el-button @click="cancelDio('dialogCsForm', 'dialogCs')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog title="回水温度控制(补偿回温)" :visible.sync="dialogBc" width="30%">
			<el-form ref="dialogBcForm" :model="dialogBcForm" :rules="Bcrules">
				<el-form-item class="m-t-10" label="补偿回水温度" prop="bchswd">
					<el-input-number v-model="dialogBcForm.bchswd"></el-input-number>
				</el-form-item>
				<el-form-item class="m-t-10">
					<el-button type="primary" @click="onSubmit('dialogBcForm')">确定</el-button>
					<el-button @click="cancelDio('dialogBcForm', 'dialogBc')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { getProject, heatExchangeStation, updateCompensationWaterTemperature, getParameterSetting, submitParameterSetting } from "@/assets/js/api";
	import { tgeValve, tgValve, psValve } from "../assets/js/api";
	export default {
		name: "dialogMode",
		props: {
			multipleSelection: {
				type: Array,
				default: () => []
			},
			bindingForm: {
				type: Function
			},
			getData: {
				type: Function
			},
			biaoshi: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				radioSelect: '',
				dialogModeSelection: false,
				dialogHm: false,
				dialogHs: false,
				dialogLl: false,
				dialogSt: false,
				dialogZn: false,
				dialogCs: false,
				dialogBc: false,
				dialogHmForm: {
					fmkd: ''
				},
				heatExchangeStationArr: [],
				dialogHsForm: {
					heatExchangeValue: ''
				},
				dialogCsForm: {
					kp: '',
					ki: '',
					kd: '',
					uMin: '',
					uMax: ''
				},
				dialogLlForm: {
					mbz: '',
					qtcs: '',
					tkzq: '',
					tkjd: '',
					zxkd: '',
					zdkd: ''
				},
				dialogBcForm: {
					bchswd: '',
					type: '',
					ids: ''
				},
				Bcrules: {
					bchswd: [{
						required: true,
						message: '请输入补偿回水温度',
						trigger: 'blur'
					}]
				},
				Csrules: {
					kp: [{
						required: true,
						message: '请输入Kp',
						trigger: 'blur'
					}],
					ki: [{
						required: true,
						message: '请输入Ki',
						trigger: 'blur'
					}],
					kd: [{
						required: true,
						message: '请输入Kd',
						trigger: 'blur'
					}],
					uMin: [{
						required: true,
						message: '请输入阀开度下限',
						trigger: 'blur'
					}],
					uMax: [{
						required: true,
						message: '请输入阀开度上限',
						trigger: 'blur'
					}]
				},
				Llrules: {
					tkzq: [{
						required: true,
						message: '请输入调控周期',
						trigger: 'blur'
					}],
					tkjd: [{
						required: true,
						message: '请输入调控精度',
						trigger: 'blur'
					}],
					zxkd: [{
						required: true,
						message: '请输入最小开度',
						trigger: 'blur'
					}],
					zdkd: [{
						required: true,
						message: '请输入最大开度',
						trigger: 'blur'
					}]
				},
				Hsrules: {
					heatExchangeValue: [{
						required: true,
						message: '请选择换热站',
						trigger: 'change'
					}],
				},
				atPresen: '',
				Hmrules: {
					fmkd: [{
						required: true,
						message: '请输入阀门开度',
						trigger: 'change'
					}],
				},
				control: [{
						'id': '1',
						'title': '手动开度控制',
						'bindingDio': 'dialogHm',
						'show': true
					},
					{
						'id': '2',
						'title': '智能控制',
						'bindingDio': 'dialogHs',
						'show': true
					},
					{
						'id': '3',
						'title': '流量控制',
						'bindingDio': 'dialogLl',
						'show': true
					},
					{
						'id': '4',
						'title': '室温控制',
						'bindingDio': 'dialogSt',
						'show': true
					},
					{
						'id': '5',
						'title': '回水温度控制',
						'bindingDio': 'dialogZn',
						'show': true
					},
					{
						'id': '6',
						'title': '参数设置',
						'bindingDio': 'dialogCs',
						'show': false
					},
					{
						'id': '7',
						'title': '回水温度控制(补偿回温)',
						'bindingDio': 'dialogBc',
						'show': false
					},
				]
			}
		},
		methods: {
			onSubmit(form) {
				if (this.atPresen === '') {
					this.$message.error('请先选择控制模式');
					return false;
				}
				if (form == "dialogHmForm") {
					this.dialogHmFormSub(form)
				}
				if (form == "dialogHsForm") {
					this.submitHeatExchangeStationView(form)
				}
				if (form == "dialogCsForm") {
					this.submitCsView(form)
				}
				if (form == "dialogBcForm") {
					this.submitBcView(form)
				}
			},
			submitBcView(form) {
				if (!this.biaoshi) {
					this.$message.error('请先选择列表');
					return false;
				}
				if (this.multipleSelection.length === 0) {
					this.$message({
						message: '当前模式需要选中信息,请先选中信息',
						type: 'warning'
					});
					return false;
				}
				this.$refs[form].validate((valid) => {
					if (valid) {
						let formObj = {
							type: this.biaoshi,
							ids: this.multipleSelection.map(item => item.id).join(','),
							bchswd: this[form]['bchswd'],
						}
						updateCompensationWaterTemperature(formObj).then(({
							data
						}) => {
							if (data.code === 200) {
								this.$message.success(data.msg);
								this.getData();
								this.cancelDio(form, this.control[this.atPresen]['bindingDio']);
							}
						})
					} else {
						return false;
					}
				});

			},
			submitCsView(form) {
				if (!this.dialogHsForm.heatExchangeValue) {
					this.$message.error('请先选择换热站');
					return false;
				}
				if (!this.biaoshi) {
					this.$message.error('请先选择列表');
					return false;
				}
				this.$refs[form].validate((valid) => {
					if (valid) {
						let formObj = {
							type: this.biaoshi,
							hrz: this['dialogHsForm']['heatExchangeValue'],
							kp: this[form]['kp'],
							ki: this[form]['ki'],
							kd: this[form]['kd'],
							uMin: this[form]['uMin'],
							uMax: this[form]['uMax']
						}
						submitParameterSetting(formObj).then(({
							data
						}) => {
							if (data.code === 200) {
								this.$message.success(data.msg);
								this.getData();
								this.cancelDio(form, this.control[this.atPresen]['bindingDio']);
							}
						})
					} else {
						return false;
					}
				});
			},

			submitHeatExchangeStationView(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let subFun = this.bindingForm;
						console.log(this.biaoshi)
						let formObj = {
							type: this.biaoshi,
							hrz: this[form]['heatExchangeValue']
						}
						subFun(formObj).then(({
							data
						}) => {
							if (data.code === 200) {
								this.$message.success(data.msg);
								this.getData();
								this.cancelDio(form, this.control[this.atPresen]['bindingDio']);
							}
						})
					} else {
						return false;
					}
				});
			},
			dialogHmFormSub(form) {
				if (this.multipleSelection.length === 0) {
					this.$message({
						message: '当前模式需要选中信息,请先选中信息',
						type: 'warning'
					});
					return false;
				}
				this.$refs[form].validate((valid) => {
					if (valid) {
						// let subFun = this.bindingForm;
						let subFun = null;
						if (this.biaoshi == '1') {
							subFun = tgeValve;
						}
						if (this.biaoshi == '2') {
							subFun = psValve;
						}
						if (this.biaoshi == '3') {
							subFun = tgValve;
						}
						let formObj = {
							idList: this.multipleSelection.map(item => item.id).join(','),
							value: this[form]['fmkd']
						}
						subFun(formObj).then(({
							data
						}) => {
							if (data.code === 200) {
								this.$message.success(data.msg);
								this.getData();
								this.cancelDio(form, this.control[this.atPresen]['bindingDio']);
							}
						})
					} else {
						return false;
					}
				});
			},
			modeSelection() {
				// if(this.multipleSelection.length === 0){
				//   this.$message({
				//     message: '请先选择信息',
				//     type: 'warning'
				//   });
				//   return false;
				// }
				this.dialogModeSelection = true;
			},
			cancelDio(form, dialog) {
				this.$refs[form].resetFields();
				this[dialog] = false;
			},
			pattern(item, key) {
				console.log(item, key)
				this.radioSelect = ''
				// 换热站信息
				if ("dialogHs" === item['bindingDio']) {
					if (!this.biaoshi) {
						this.$message.error('请先选择列表');
						return false;
					}
					this.heatExchangeStationArr = []
					// heatExchangeStation({ type: this.biaoshi }).then(({ data }) => {
						// if (data.data) {
						// 	this.heatExchangeStationArr = data.data
						// }
					// })
				}
				//参数信息
				if ("dialogCs" === item['bindingDio']) {
					if (!this.dialogHsForm.heatExchangeValue) {
						this.$message.error('请先选择换热站');
						return false;
					}
					if (!this.biaoshi) {
						this.$message.error('请先选择列表');
						return false;
					}
					let csGet = {
						type: this.biaoshi,
						hrz: this.dialogHsForm.heatExchangeValue
					}
					this.dialogHs = false;
					getParameterSetting(csGet).then(({
						data
					}) => {
						this.dialogCsForm = data.data
					})
				}
				this.dialogModeSelection = false
				this[item['bindingDio']] = true;
				this.atPresen = key
			}
		}
	}
</script>

<style scoped>
	/deep/.el-dialog {
		background: #051A3B;
	}
	/deep/.el-dialog__title{
		color: #E5F6FF;
	}
	/deep/.el-radio__label{
		color: #E5F6FF;
	}
	.m-t-20 {
		margin-bottom: 20px;
	}
	.m-t-10 {
		margin-bottom: 20px;
	}
	.project {
		padding-left: 100px;
	}
	.deep.el-dialog__body {
		padding: 0px 20px;
	}
	.el-form--inline {
		.el-form-item {
			display: block;
		}
	}
	.parameter {
		display: flex;
		.settingcs {
			margin-left: 30px;
		}
	}
</style>
